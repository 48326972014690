import { Flex, Spinner } from '@chakra-ui/react';
import React from 'react';

const LoadingSpinner = () => {
  return (
    <Flex p="5" justifyContent="center" alignItems="center">
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="lg"
      />
    </Flex>
  );
};

export default LoadingSpinner;
