import {
  ContractExecuteTransaction,
  ContractFunctionParameters,
} from '@hashgraph/sdk';
import {
  getProvider,
  hashconnect,
  useHashconnectService,
} from '../../context/HashconnectProvider';
import { AccountId, PrivateKey } from '@hashgraph/sdk';
import { hethers } from '@hashgraph/hethers';
import { getAccountAddressFromAccountId, getAccountId } from './wallet';
// import { Signer } from '@hethers/abstract-signer';
import DDNSABI from './build/artifacts/DDNS.json';

const DDNSContractId = process.env.REACT_APP_DDNS_CONTRACT_ID;

export const inviteNew = async (topic, accountId, id) => {
  try {
    const provider = getProvider({ topic, accountId });
    if (!provider) {
      throw new Error('Provider not found!');
    }

    const signer = hashconnect.getSigner(provider);

    if (!signer) {
      throw new Error('Signer not found!');
    }

    console.log('invitedCode', id);

    const tx = new ContractExecuteTransaction()
      .setContractId(DDNSContractId)
      .setGas(5000000)
      .setFunction('invite', new ContractFunctionParameters().addString(id));

    await tx.freezeWithSigner(signer);
    const txRes = await tx.executeWithSigner(signer);

    if (!txRes) {
      throw new Error('No transaction response found!');
    }

    return txRes;
  } catch (error) {
    console.error('createProposal =>', error);
    return null;
  }
};

export const acceptInvite = async (topic, accountId, id) => {
  try {
    const provider = getProvider({ topic, accountId });
    if (!provider) {
      throw new Error('Provider not found!');
    }

    const signer = hashconnect.getSigner(provider);

    if (!signer) {
      throw new Error('Signer not found!');
    }

    console.log('invitedCode', id);

    const tx = new ContractExecuteTransaction()
      .setContractId(DDNSContractId)
      .setGas(5000000)
      .setFunction(
        'acceptInvite',
        new ContractFunctionParameters().addString(id)
      );

    await tx.freezeWithSigner(signer);
    const txRes = await tx.executeWithSigner(signer);

    if (!txRes) {
      throw new Error('No transaction response found!');
    }

    return txRes;
  } catch (error) {
    console.error('createProposal =>', error);
    return null;
  }
};
