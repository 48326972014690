import { StepsStyleConfig as Steps } from 'chakra-ui-steps';

export const components = {
  Steps,
  Button: {
    baseStyle: {
      borderRadius: 'md',
      color: 'white',
      _focus: {
        ring: '0px',
      },
    },
    variants: {
      solid: {
        bg: '#2D84EB',
        _hover: {
          bg: '#2d82ebe1',
        },
        _focus: {},
      },
      link: {
        bg: 'white',
        borderWidth: '0px',
        padding: '8px 20px',
        fontWeight: 500,
        color: 'black',
        _hover: {
          bg: 'white',
        },
        _focus: {},
      },
      outline: {
        bg: 'white',
        border: '1px solid',
        borderColor: '#E5E8E9',
        color: 'black',
        _hover: {
          bg: 'gray.100',
        },
        _focus: {},
      },
      defaultProps: {
        variant: 'outline',
      },
    },
  },
};
