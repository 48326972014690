import React from 'react';
import { ProtectedRoute } from './ProtectedRoute';

const Register = React.lazy(() => import('../pages/Register'));
const Login = React.lazy(() => import('../pages/Login'));

const authRoutes = [
  {
    element: (
      <ProtectedRoute type="auth">
        <Login />
      </ProtectedRoute>
    ),
    path: '/login',
  },
];

export default authRoutes;
