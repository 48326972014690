import React from 'react';
import { useToast } from '@chakra-ui/toast';
import { HashConnect } from 'hashconnect';
import { HashConnectConnectionState } from 'hashconnect/dist/types';
import { createCustomToast } from '../components/CustomToast';

//create the hashconnect instance
export const hashconnect = new HashConnect();

const walletConnectionToast = 'wallet-connection';

const appMetadata = {
  name: 'dDNS-DAO',
  description: 'dDNS-DAO',
};

const HashconnectServiceContext = React.createContext({});

export const getProvider = ({ network = 'testnet', topic, accountId }) => {
  return hashconnect.getProvider(network, topic, accountId);
};

export const HashconnectAPIProvider = ({
  children,
  metaData,
  network,
  debug,
}) => {
  const toast = useToast();
  const [state, setState] = React.useState({});

  const initHashconnect = async () => {
    let initData = await hashconnect.init(
      metaData ?? appMetadata,
      network,
      false
    );
    const topic = initData?.topic;
    const pairingString = initData?.pairingString;

    const pairingData = initData.savedPairings[0];

    setState(exState => ({
      ...exState,
      topic,
      pairingData,
      pairingString,
      state: HashConnectConnectionState.Disconnected,
    }));
  };

  const onFoundExtension = data => {
    setState(exState => ({ ...exState, availableExtension: data }));
  };

  const onParingEvent = data => {
    if (!toast.isActive(walletConnectionToast)) {
      createCustomToast({
        id: walletConnectionToast,
        description: 'Wallet connected successfully!',
        status: 'info',
      });
    }
    setState(exState => ({ ...exState, pairingData: data.pairingData }));
  };

  const onConnectionChange = state => {
    setState(exState => ({ ...exState, state }));
  };

  //register events
  React.useEffect(() => {
    hashconnect.foundExtensionEvent.on(onFoundExtension);
    hashconnect.pairingEvent.on(onParingEvent);
    hashconnect.connectionStatusChangeEvent.on(onConnectionChange);
    return () => {
      hashconnect.foundExtensionEvent.off(onFoundExtension);
      hashconnect.pairingEvent.on(onParingEvent);
      hashconnect.connectionStatusChangeEvent.off(onConnectionChange);
    };
  }, []);

  //Call Initialization
  React.useEffect(() => {
    initHashconnect();
  }, []);

  return (
    <HashconnectServiceContext.Provider value={{ ...state, setState, network }}>
      {children}
    </HashconnectServiceContext.Provider>
  );
};

export const useHashconnectService = () => {
  const value = React.useContext(HashconnectServiceContext);
  const { topic, pairingData, network, userDetails, setState } = value;

  const toast = useToast();

  const connectToExtension = async () => {
    hashconnect.connectToLocalWallet();
  };

  const sendTransaction = async (
    trans,
    acctToSign,
    return_trans = false,
    hideNfts = false
  ) => {
    const transaction = {
      topic,
      byteArray: trans,

      metadata: {
        accountToSign: acctToSign,
        returnTransaction: return_trans,
        hideNft: hideNfts,
      },
    };

    return await hashconnect.sendTransaction(topic, transaction);
  };

  const disconnect = () => {
    hashconnect.disconnect(pairingData?.topic);
    setState(exState => ({ ...exState, pairingData: null }));
    if (!toast.isActive(walletConnectionToast)) {
      createCustomToast({
        id: walletConnectionToast,
        description: 'Wallet disconnected successfully!',
        status: 'info',
      });
    }
  };

  const loginUser = (
    id,
    employeeName,
    companyName,
    isDaoMember,
    isAdmin,
    isSuperDao
  ) => {
    setState(exState => ({
      ...exState,
      userDetails: {
        id,
        name: employeeName,
        company: companyName,
        isDaoMember,
        isAdmin,
        isSuperDao,
      },
      isUserLoggedIn: true,
    }));
  };

  const getLoggedInUser = () => {
    return userDetails;
  };

  const logoutUser = () => {
    setState(exState => ({
      ...exState,
      userDetails: null,
      isUserLoggedIn: false,
    }));
  };

  const requestAccountInfo = async () => {
    const request = {
      topic,
      network,
      multiAccount: true,
    };

    await hashconnect.requestAdditionalAccounts(topic, request);
  };

  const clearPairings = () => {
    hashconnect.clearConnectionsAndData();
    setState(exState => ({ ...exState, pairingData: null }));
  };

  const getSigner = () => {
    console.log(hashconnect.getSigner());
  };

  return {
    ...value,
    connectToExtension,
    sendTransaction,
    disconnect,
    requestAccountInfo,
    clearPairings,
    loginUser,
    getLoggedInUser,
    logoutUser,
    getSigner,
  };
};
