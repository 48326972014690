import { PrivateKey } from '@hashgraph/cryptography';
import { hethers } from '@hashgraph/hethers';
import { AccountId } from '@hashgraph/sdk';

export const getAccountId = pairingData => {
  if (pairingData && pairingData.accountIds[0]) {
    return pairingData?.accountIds[0];
  } else {
    return null;
  }
};

export const getAccountAddressFromAccountId = accountId => {
  try {
    return hethers.utils.getAddressFromAccount(accountId);
  } catch (error) {
    console.error('getAccountAddressFromAccountId =>', error);
    return null;
  }
};

export const getSystemWallet = () => {
  try {
    const signerId = AccountId.fromString(
      process.env.REACT_APP_SYSTEM_WALLET_ACCOUNT_ID
    );
    const signerKey = PrivateKey.fromString(
      process.env.REACT_APP_SYSTEM_WALLET_PRIVATE_KEY
    );
    const provider = hethers.providers.getDefaultProvider('testnet');

    const eoaAccount = {
      account: signerId,
      privateKey: `0x${signerKey.toStringRaw()}`,
    };

    const wallet = new hethers.Wallet(eoaAccount, provider);

    return wallet;
  } catch (error) {
    console.error('getSystemWallet =>', error);
    return null;
  }
};

export const shortenAddress = address => {
  if (address) {
    return address.slice(0, 5) + '...' + address.slice(-4);
  } else {
    return '---';
  }
};
