import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Box } from '@chakra-ui/layout';
import Routing from './routes';
import { HashconnectAPIProvider } from './context/HashconnectProvider';
import SpinnerUtil from './components/Spinner';
import CustomToast from './components/CustomToast';

function App() {
  return (
    <HashconnectAPIProvider network="testnet">
      <Box className="app">
        <BrowserRouter>
          <Suspense fallback={<SpinnerUtil />}>
            <Routing />
          </Suspense>
        </BrowserRouter>
        <CustomToast />
      </Box>
    </HashconnectAPIProvider>
  );
}

export default App;
