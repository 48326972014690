import { extendTheme } from '@chakra-ui/react';
import { components } from './components';

const tokens = {
  colors: {
    light: {
      'bg-brand-primary': 'white',
      'bg-brand-secondary': '#2D84EB',
      'bg-brand-light': '#2D84EB33',
      'text-brand': '#8247E5',
    },
    dark: {
      'bg-brand-primary': 'gray.800',
      'bg-brand-secondary': '#2D84EB',
      'bg-brand-light': '#2D84EB33',
      'text-brand': '#8247E5',
    },
  },
};

const semanticTokens = {
  colors: {
    'bg-brand-primary': {
      default: tokens.colors.light['bg-brand-primary'],
      _dark: tokens.colors.dark['bg-brand-primary'],
    },
    'bg-brand-secondary': {
      default: tokens.colors.light['bg-brand-secondary'],
      _dark: tokens.colors.dark['bg-brand-secondary'],
    },
    'bg-brand-light': {
      default: tokens.colors.light['bg-brand-light'],
      _dark: tokens.colors.dark['bg-brand-light'],
    },
    'text-brand': {
      default: tokens.colors.light['text-brand'],
      _dark: tokens.colors.dark['text-brand'],
    },
  },
};

const styles = {
  global: {
    body: {
      background: 'bg-brand-primary',
    },
  },
};

const breakpoints = {
  sm: '320px',
  md: '768px',
  lg: '960px',
  xl: '1200px',
  '2xl': '1536px',
};

const customTheme = extendTheme({
  styles,
  semanticTokens,
  components,
  breakpoints
});

export default customTheme;
