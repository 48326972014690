import { Button } from '@chakra-ui/react';
import { useHashconnectService } from '../context/HashconnectProvider';

const ConnectWallet = ({
  connectWalletText = 'Connect Wallet',
  disconnectWalletText = 'Disconnect',
}) => {
  const { connectToExtension, disconnect, pairingData, availableExtension } =
    useHashconnectService();

  const hashpackWalletConnectHandler = async () => {
    if (availableExtension && !pairingData) {
      connectToExtension();
    } else {
      alert(
        'Please install hashconnect wallet extension first. from chrome web store.'
      );
    }
  };
  const hashpackDisconnectHandler = async () => {
    if (availableExtension && pairingData) {
      disconnect();
    }
  };

  if (pairingData) {
    return (
      <Button variant="outline" onClick={hashpackDisconnectHandler}>
        {disconnectWalletText} {pairingData?.accountIds}
      </Button>
    );
  } else {
    return (
      <Button variant="outline" onClick={hashpackWalletConnectHandler}>
        {connectWalletText}
      </Button>
    );
  }
};

export default ConnectWallet;
