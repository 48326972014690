import { createStandaloneToast } from '@chakra-ui/toast';

const { ToastContainer, toast } = createStandaloneToast();

const createCustomToast = ({
  id,
  title,
  description,
  status,
  isClosable = true,
  position = 'bottom-left',
}) => {
  if (!toast.isActive(id)) {
    return toast({
      id,
      title,
      description,
      status,
      isClosable,
      position,
    });
  }
};

const CustomToast = () => {
  return <ToastContainer />;
};

export default CustomToast;
export { createCustomToast };
