import {
  Box,
  VStack,
  HStack,
  Button,
  Text,
  Heading,
  Center,
  Divider,
  Image,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import ConnectWallet from '../components/ConnectWallet';
import { createCustomToast } from '../components/CustomToast';
import LoadingSpinner from '../components/LoadingSpinner';
import { useHashconnectService } from '../context/HashconnectProvider';
import { getInviteDetails, updateInviteStatus } from '../utils/api/user';
import { acceptInvite } from '../utils/blockchain/invite';
import {
  getAccountAddressFromAccountId,
  getAccountId,
} from '../utils/blockchain/wallet';

const DaoInvite = () => {
  const { pairingData } = useHashconnectService();
  let [searchParams] = useSearchParams();
  const [isLoadingInviteDetails, setIsLoadingInviteDetails] = useState(true);
  const [isChangingInviteStatus, setIsChangingInviteStatus] = useState({});
  const [inviteDetails, setInviteDetails] = useState();

  const id = searchParams.get('id');

  const fetchInviteDetails = async () => {
    setIsLoadingInviteDetails(true);
    try {
      const inviteDetails = await getInviteDetails(id);

      setInviteDetails(inviteDetails);
    } catch (error) {
      console.error('fetchInviteDetails =>', error);
    }
    setIsLoadingInviteDetails(false);
  };

  const changeInviteStatus = async status => {
    setIsChangingInviteStatus(prevState => {
      return { ...prevState, [status]: true };
    });

    try {
      const accountAddress = getAccountAddressFromAccountId(
        getAccountId(pairingData)
      );
      await acceptInvite(pairingData.topic, getAccountId(pairingData), id);
      const isInviteStatusUpdated = await updateInviteStatus(
        id,
        status,
        accountAddress
      );

      if (!isInviteStatusUpdated) {
        throw new Error('error in updating invite status');
      }

      createCustomToast({
        id: 'invite-update-success',
        description: `Invite ${status} successfully!`,
        status: 'success',
      });

      fetchInviteDetails();
    } catch (error) {
      console.error('changeInviteStatus =>', error);
      createCustomToast({
        id: 'invite-update-fail',
        description: `Something went wrong while ${
          status === 'accepted' ? 'accepting' : 'declining'
        } invite!`,
        status: 'error',
      });
    }
    setIsChangingInviteStatus(prevState => {
      return { ...prevState, [status]: false };
    });
  };

  useEffect(() => {
    if (id) {
      fetchInviteDetails();
    }
  }, []); // eslint-disable-line

  if (!id) {
    return (
      <Center bg="white" minH="100vh">
        <Box
          w="90%"
          maxW="400px"
          borderRadius="lg"
          boxShadow="lg"
          position="relative"
          p={4}
        >
          <VStack spacing={6} h="90%" justify="flex-end">
            <Text maxW="180px" align="center" color="red.500" fontSize="xl">
              Invalid Invite!
            </Text>
          </VStack>
        </Box>
      </Center>
    );
  }

  const isExpired = new Date(inviteDetails?.expiredAt) < new Date();

  return (
    <Center bg="white" minH="100vh" flexDirection="column">
      {isLoadingInviteDetails && <LoadingSpinner />}

      {!isLoadingInviteDetails && !inviteDetails && (
        <Box
          w="90%"
          maxW="400px"
          borderRadius="lg"
          boxShadow="lg"
          position="relative"
          p={4}
        >
          <VStack spacing={6} h="90%" justify="flex-end">
            <Text maxW="180px" align="center" color="red.500" fontSize="xl">
              Invalid Invite!
            </Text>
          </VStack>
        </Box>
      )}

      {!isLoadingInviteDetails && inviteDetails?.status === 'rejected' && (
        <Box
          w="90%"
          maxW="400px"
          borderRadius="lg"
          boxShadow="lg"
          position="relative"
          p={4}
        >
          <VStack spacing={6} h="90%" justify="flex-end">
            <Text maxW="180px" align="center" color="red.500" fontSize="xl">
              Invite has already been rejected!
            </Text>
          </VStack>
        </Box>
      )}

      {!isLoadingInviteDetails && inviteDetails?.status === 'accepted' && (
        <Box
          w="90%"
          maxW="400px"
          borderRadius="lg"
          boxShadow="lg"
          position="relative"
          p={4}
        >
          <VStack spacing={6} h="90%" justify="flex-end">
            <Text maxW="180px" align="center" color="green.500" fontSize="xl">
              Invite has already been accepted!
            </Text>
            <Text maxW="250px" align="center" color="green.500" fontSize="xl">
              Please <a href="/login" style={{color: '#2D84EB'}}>Login</a> to Continue
            </Text>
          </VStack>
        </Box>
      )}

      {!isLoadingInviteDetails && isExpired && (
        <Box
          w="90%"
          maxW="400px"
          borderRadius="lg"
          boxShadow="lg"
          position="relative"
          p={4}
        >
          <VStack spacing={6} h="90%" justify="flex-end">
            <Text maxW="180px" align="center" color="red.500" fontSize="xl">
              Invite has been expired!
            </Text>
          </VStack>
        </Box>
      )}

      {!isLoadingInviteDetails &&
        !isExpired &&
        inviteDetails?.status === 'pending' && (
          <Box
            w="90%"
            maxW="400px"
            height="360px"
            borderRadius="lg"
            boxShadow="lg"
            position="relative"
          >
            <Center position="absolute" top="-16" w="full">
              <Image
                src="/logo192.png"
                alt="dDNS-DAO"
                bg="gray.300"
                rounded="full"
                w="160px"
                h="160px"
                mb={5}
              />
            </Center>
            <VStack spacing={6} h="90%" justify="flex-end">
              <Heading as="h1" size="xl">
                dDNS-DAO
              </Heading>
              <Divider />
              <Text maxW="180px" align="center" color="">
                Accept the invite to become a DAO member
              </Text>
              <HStack w="80%" justify="space-between">
                <Button
                  w="45%"
                  variant="outline"
                  onClick={() => {
                    changeInviteStatus('rejected');
                  }}
                  isDisabled={isChangingInviteStatus?.accepted}
                  isLoading={isChangingInviteStatus?.rejected}
                >
                  Decline
                </Button>
                {pairingData ? (
                  <Button
                    w="45%"
                    onClick={() => {
                      changeInviteStatus('accepted');
                    }}
                    isDisabled={isChangingInviteStatus?.rejected}
                    isLoading={isChangingInviteStatus?.accepted}
                  >
                    Accept
                  </Button>
                ) : (
                  <ConnectWallet />
                )}
              </HStack>
            </VStack>
          </Box>
        )}
      {!isLoadingInviteDetails &&
        !isExpired &&
        inviteDetails?.status === 'pending' &&
        pairingData && (
          <Box my={4}>
            <ConnectWallet />
          </Box>
        )}
    </Center>
  );
};

export default DaoInvite;
