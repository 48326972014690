import React from 'react';
import DaoInvite from '../pages/DaoInvite';

const Home = React.lazy(() => import('../pages/Home'));

const normalRoutes = [
  {
    element: <Home />,
    path: '/',
  },
  {
    element: <DaoInvite />,
    path: '/dao-invite',
  },
];

export default normalRoutes;
