import React from 'react';
import { ProtectedRoute } from './ProtectedRoute';

const Dashboard = React.lazy(() => import('../pages/Dashboard'));
const NewDtlds = React.lazy(() => import('../pages/NewDtlds'));
const Profile = React.lazy(() => import('../pages/Profile'));
const DaoMembers = React.lazy(() => import('../pages/DaoMembers'));
const SentInvites = React.lazy(() => import('../pages/SentInvites'));
const NewProposal = React.lazy(() => import('../pages/NewProposal'));
const AllProposals = React.lazy(() => import('../pages/AllProposals'));

const dashboardRoutes = [
  {
    element: (
      <ProtectedRoute>
        <Dashboard />
      </ProtectedRoute>
    ),
    path: '/dashboard',
  },
  {
    element: (
      <ProtectedRoute>
        <NewDtlds />
      </ProtectedRoute>
    ),
    path: '/newdtld',
  },
  {
    element: (
      <ProtectedRoute>
        <NewProposal />
      </ProtectedRoute>
    ),
    path: '/new-proposal',
  },
  {
    element: (
      <ProtectedRoute>
        <AllProposals />
      </ProtectedRoute>
    ),
    path: '/all-proposals',
  },
  {
    element: (
      <ProtectedRoute type="admin">
        <DaoMembers />
      </ProtectedRoute>
    ),
    path: '/dao-members',
  },
  {
    element: (
      <ProtectedRoute type="admin">
        <SentInvites />
      </ProtectedRoute>
    ),
    path: '/dao-invites',
  },
  {
    element: (
      <ProtectedRoute>
        <Profile />
      </ProtectedRoute>
    ),
    path: '/profile',
  },
];

export default dashboardRoutes;
