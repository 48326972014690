import React from 'react';
import { Container, Flex, Spinner } from '@chakra-ui/react';

const SpinnerUtil = () => {
  return (
    <Container maxW="full" h="100vh" bg="white">
      <Flex h="100%" justifyContent="center" alignItems="center">
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      </Flex>
    </Container>
  );
};

export default SpinnerUtil;
