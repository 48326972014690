import { instance } from './index';

export const getLoginDetails = async walletAddress => {
  try {
    if (!walletAddress) {
      throw new Error('walletAddress is not defined!');
    }

    const response = await instance.post('/login', { walletAddress });

    const result = response.data;

    return result?.data;
  } catch (error) {
    console.error('getLoginDetails =>', error);
    return null;
  }
};

export const signupUser = async (
  walletAddress,
  companyName,
  employeeName,
  email
) => {
  try {
    if (!walletAddress) {
      throw new Error('walletAddress is not defined!');
    }

    if (!companyName) {
      throw new Error('companyName is not defined!');
    }

    if (!employeeName) {
      throw new Error('employeeName is not defined!');
    }

    if (!email) {
      throw new Error('email is not defined!');
    }

    const response = await instance.post('/signup', {
      walletAddress,
      companyName,
      employeeName,
      email,
    });

    const result = response.data;

    return result?.data;
  } catch (error) {
    console.error('signupUser =>', error);
    return null;
  }
};

export const getDaoMembers = async () => {
  try {
    const response = await instance.get('/dao-members');

    const result = response.data;

    return result?.data;
  } catch (error) {
    console.error('getDaoMembers =>', error);
    return null;
  }
};

export const getSentInvites = async () => {
  try {
    const response = await instance.get('dao-members/invites');

    const result = response.data;

    return result?.data;
  } catch (error) {
    console.error('inviteDaoMembers =>', error);
    return null;
  }
};

export const inviteDaoMembers = async (email, expiredAt) => {
  try {
    if (!email) {
      throw new Error('email is not defined!');
    }

    const response = await instance.post('dao-members/invite', {
      email,
      expiredAt,
    });

    const result = response.data;

    return result?.data;
  } catch (error) {
    console.error('inviteDaoMembers =>', error);
    return null;
  }
};

export const getInviteDetails = async id => {
  try {
    if (!id) {
      throw new Error('id is not defined!');
    }

    const response = await instance.get(`dao-members/invite/${id}`);

    const result = response.data;

    return result?.data;
  } catch (error) {
    console.error('getInviteDetails =>', error);
    return null;
  }
};

export const updateInviteStatus = async (id, status, walletAddress) => {
  try {
    if (!id || !status) {
      throw new Error('wrong parameters!');
    }

    const response = await instance.put(`dao-members/invite`, {
      id,
      status,
      walletAddress,
    });

    const result = response.data;

    return result?.data;
  } catch (error) {
    console.error('updateInviteStatus =>', error);
    return null;
  }
};
